import styles from './CourseList.scss';
import { CourseCard } from '@/components/organisms/card/CourseCard';

type ElementProps = JSX.IntrinsicElements['ol'];
type CourseList = {
  courses: CourseCard['course'][];
};
type Props = ElementProps & CourseList;

/** organisms/CourseList */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CourseList: React.FC<Props> = ({ children, ...props }) => {
  const { courses, ...restProps } = props;
  return (
    <ol {...restProps} className="course-list">
      {courses.map((course) => (
        <li key={course.course_key}>
          <CourseCard course={course} />
        </li>
      ))}
      <style jsx>{styles}</style>
    </ol>
  );
};
