import styles from './CourseCard.scss';
import { useMemo } from 'react';
import { pagesPath } from '@/lib/$path';
import Link from 'next/link';
import { UseApiContextState } from '@/hooks/api';
import { BaseButton } from '@/components/atoms/button/BaseButton';

type ElementProps = JSX.IntrinsicElements['div'];
export type CourseCard = {
  course: ReturnTypeDigPromise<UseApiContextState['api']['users']['courses']['$get']>['data']['courses'][number];
};
type Props = ElementProps & CourseCard;

/** organisms/CourseCard */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CourseCard: React.FC<Props> = ({ children, ...props }) => {
  const { course, ...restProps } = props;

  const courseUrl = useMemo(() => pagesPath.courses._courseKey(course.course_key).$url(), [course]);

  return (
    <div {...restProps} className="course-card">
      <div className="course-card-header">
        <h3>{course.name}</h3>
        <p className="course-card-price">￥{course.price.toLocaleString()}</p>
      </div>
      <p className="course-card-description">{course.description}</p>

      <div className="radius-button">
        <Link href={courseUrl}>
          <a className="radius-button-inner">詳細をみる</a>
        </Link>
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};
