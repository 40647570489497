import styles from './TeacherCard.scss';
import { TeacherIcon } from '@/components/atoms/image/TeacherIcon';
import { UseApiContextState } from '@/hooks/api';

type TeacherInfo = Exclude<
  | ReturnTypeDigPromise<UseApiContextState['api']['schools']['school']['$get']>['data']['school']['teacher']
  | ReturnTypeDigPromise<UseApiContextState['api']['users']['school']['$get']>['data']['school']['teacher'],
  null
>;

type ElementProps = JSX.IntrinsicElements['div'];
type TeacherCard = Partial<Pick<TeacherInfo, 'name' | 'title' | 'profile' | 'icon_url'>>;
type Props = ElementProps & TeacherCard;

/** TeacherCard */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TeacherCard: React.FC<Props> = ({ children, ...props }) => {
  const { name, title, profile, icon_url, ...restProps } = props;
  return (
    <div {...restProps} className="teacher-card">
      <TeacherIcon src={icon_url || ''} alt={name} size="60px" />
      <p className="teacher-card__name">{name || '未設定'}</p>
      <p className="teacher-card__category">{title || '未設定'}</p>
      <p className="teacher-card__profile">{profile || '未設定'}</p>
      <style jsx>{styles}</style>
    </div>
  );
};
