import { classNames } from '@/lib/classNames';
import React from 'react';
import styles from './CoverImage.scss';

type ElementProps = JSX.IntrinsicElements['div'];
type CoverImage = {
  src?: string | null;
  alt?: string;
};
type Props = ElementProps & CoverImage;

/** CoverImage */
export const CoverImage: React.FC<Props> = React.memo(({ src, alt, className }) => {
  return (
    <>
      {src && <img className={classNames('cover-image', className)} src={src} alt={alt || ''} />}
      <style jsx>{styles}</style>
    </>
  );
});

CoverImage.displayName = 'CoverImage';
