import { classNames } from '@/lib/classNames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMemo } from 'react';
import styles from './LoadedView.scss';

type ElementProps = JSX.IntrinsicElements['div'];
type LoadedView = {
  loading: boolean;
  loadingPosition?: 'center' | 'top';
  backgroundImage?: string;
};
type Props = ElementProps & LoadedView;

/** LoadedView */
export const LoadedView: React.FC<Props> = ({ children, ...props }) => {
  const { className, loading, style, loadingPosition = 'top', backgroundImage, ...restProps } = props;

  const backgroundImageStyle = useMemo(
    () => (backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : null),
    [backgroundImage]
  );

  if (loading) {
    return (
      <div
        {...restProps}
        className={classNames(className, 'loading', loadingPosition)}
        style={{ ...style, ...backgroundImageStyle }}
      >
        <CircularProgress color="primary" />
        <style jsx>{styles}</style>
      </div>
    );
  }

  return <>{children}</>;
};
