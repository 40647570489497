import styles from './Home.scss';
import Head from 'next/head';
import { GlobalLayout } from '@/components/organizers/layout/GlobalLayout';
import { SideMenu } from '@/components/organisms/layout/SideMenu';
import { useSchoolContext } from '@/hooks/school';
import { CourseList } from '@/components/organisms/list/CourseList';
import { TeacherCard } from '@/components/molecules/card/TeacherCard';
import { CoverImage } from '@/components/atoms/image/CoverImage';
import { useEffect, useState } from 'react';
import { LoadedView } from '@/components/atoms/loading/LoadedView';
import { SchoolName } from '@/components/atoms/SchoolName';

/** organizers/Home */
export const Home: React.FC = () => {
  const { school, courses, getSchoolInfo } = useSchoolContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([getSchoolInfo()]).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <GlobalLayout>
      <main>
        <LoadedView loading={loading}>
          <CoverImage className="hero-cover" src={school?.cover_url} />
          <div className="side-layout">
            <div className="side-layout-row">
              <div className="column main-column">
                <div className="hero">
                  <div className="hero-school-info">
                    <h1 className="hero-school-name">{school?.name}</h1>
                    <p className="hero-school-catchphrase">{school?.catchphrase}</p>
                  </div>
                </div>

                <div className="sp-only school-description">{school?.description}</div>

                <div className="sp-only school-teacher">
                  <TeacherCard {...school?.teacher} />
                </div>

                <div className="inner">{courses && <CourseList courses={courses} />}</div>
              </div>
              <div className="pc-only column side-column">
                <SideMenu school={school} />
              </div>
            </div>
          </div>
        </LoadedView>
      </main>

      <style jsx>{styles}</style>
    </GlobalLayout>
  );
};
