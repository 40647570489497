import styles from './SideMenu.scss';
import { TeacherCard } from '@/components/molecules/card/TeacherCard';
import { SchoolData } from '@/hooks/school/schoolInterface';

// type SideMenuProps = JSX.IntrinsicElements['main'];
type Props = {
  school?: SchoolData;
};

/** organisms/SideMenu */
export const SideMenu: React.FC<Props> = ({ school }) => {
  return (
    <aside className="side-menu">
      <div className="block about-block">
        <p>{school?.description}</p>
      </div>

      <div className="block profile-block">
        <TeacherCard {...school?.teacher} />
      </div>

      <style jsx>{styles}</style>
    </aside>
  );
};
