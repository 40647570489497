import styles from './TeacherIcon.scss';
import { Icon } from '@material-ui/core';

type ElementProps = JSX.IntrinsicElements['img'];
type TeacherIcon = {
  size: string;
};
type Props = ElementProps & TeacherIcon;

/** TeacherIcon */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TeacherIcon: React.FC<Props> = ({ children, ...props }) => {
  const { src, alt, size } = props;
  const style = {
    height: size,
    width: size
  };

  return (
    <div className="teacher-icon" style={style}>
      {(() => {
        if (src) {
          return <img className="teacher-icon__image" src={src || ''} alt={alt || ''} />;
        } else {
          return (
            <Icon className="teacher-icon__image" style={{ fontSize: `calc(${size} * .9)` }}>
              person
            </Icon>
          );
        }
      })()}
      <style jsx>{styles}</style>
    </div>
  );
};
